.users_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  padding: 20px;
}

.users_container .ant-input-suffix {
  cursor: pointer !important;
}

.users_container .ant-input-affix-wrapper {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 992px) {
  .users_container .ant-input-affix-wrapper {
    margin-bottom: 0px !important;
  }
}

.users_container .ant-table-body {
  max-height: 65vh !important;
}

@media screen and (max-width: 992px) {
  .users_container .ant-table-body {
    max-height: 60vh !important;
  }
}