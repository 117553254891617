@import 'styles/variables';

.page_container {
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-x: hidden;
    flex: 1;
    background-image: url('../../../resources/images/landing_page_background.jpg');
    background-size: cover;
}