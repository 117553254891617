@import 'styles/variables.scss';

body {
  line-height: normal;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
}

h1 {
    font-size: $font-size-xxl;
}

h2 {
    font-size: $font-size-xl;
}

h3 {
    font-size: $font-size-lg;
}

h4 {
    font-size: $font-size-md;
}

h5 {
    font-size: $font-size-sm;
}

h6 {
    font-size: $font-size-xs;
}

h1, h2, h3, h4, h5, h6 {
    font-family: -apple-system, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    margin: 0.5em 0px;
}
