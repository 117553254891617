.sidebar_container {
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  width: 200px;
}

@media screen and (max-width: 992px) {
  .sidebar_container {
    display: none;
  }
}

.menu_list_container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.menu_list_container a {
  text-decoration: none;
  color: #5f6368;
  padding: 20px;
}

.menu_list_container a:hover {
  color: salmon;
}

.dropdown_menu_container {
  display: flex;
  justify-content: space-between;
}

.active_link {
  color: #04798f !important;
}

/* .menu_list_container a:nth-child(odd) {
  background-color: #087084;
} */

.menu_icon {
  margin-right: 10px !important;
}
