.landing_page_container {
  background: url('../../../resources/images/landing_page_background.jpg');
  height: 100vh;
  width: 100%;
  background-size: cover;
}

.content_container {
  background: -webkit-linear-gradient(to bottom, #06ceb3, #04798f);
  background: linear-gradient(to bottom, #06ceb3, #04798f);
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.signup_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join_text {
  color: white;
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.275px;
}

.signup_text {
  color: black;
  font-size: 14px;
  font-weight: 600;
  background: #ffffff;
  border-radius: 10px;
  padding: 12px 24px;
}

.signin_text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.already_member_text {
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.signin_text {
  color: white;
  font-size: 18px;
  font-weight: 900;
  margin-top: 12px;
}

.signin_text a :hover {
  color: black;
}

.select_container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.select {
  min-width: 90px !important;
  background: none !important;
  border: 1px solid white !important;
  font-weight: 700;
  box-shadow: none !important;
  color: white !important;
  margin-top: 12px;
}

.seperator {
  width: 110px;
  margin-top: 10px;
  height: 2px;
  background-color: white;
  opacity: 0.7;
}

.logo {
  height: 120px;
  width: auto;
  /* padding: 30px; */
}
