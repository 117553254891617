// colors
$color-primary-blue: #1890ff;

$color-black: #000000;
$color-primary-black: #404040;
$color-primary-grey: #777777;

$color-white: #ffffff;

// backgrounds
$bg-primary-grey: #efeff4;
$bg-secondary-grey: #c0c8d0;
$bg-tertiary-grey: #979797;

$bg-primary-turquoise: #8BD6DB;
$bg-secondary-turquoise: #51b0d6;

$bg-primary-green: #79F685;
$bg-secondary-green: #20C997;

$bg-primary-blue: #1890ff;

$bg-primary-red: #F5222D;

// button colors
$button-background-color: #1890ff;
$button-background-color-active: #62a5ff;
$button-background-color-disabled: #cccccc;

$button-foreground-color: #ffffff;
$button-foreground-color-active: #ffffff;
$button-foreground-color-disabled: #ffffff;

// borders
$border-primary-grey: #979797;
$border-secondary-grey: #D7D7D7;
$border-tertiary-grey: #d8dee2;

$dialog-background-color: #efeff4;
$header-background-color: #1890ff;

$input-bg-color-default: #ffffff;
$input-bg-color-disabled: #efeff4;

$border-color-default: $border-tertiary-grey;
$border-color-active: $bg-primary-blue;

$text-color-primary: #404040;
$text-color-secondary: #777777;
$text-color-tertiary: #979797;

// border-radius
$border-radius-sm: 4px;
$border-radius-md: 6px;
$border-radius-lg: 8px;
$border-rounded: 50% 50%;

// paddings
$padding-xs: 2px;
$padding-sm: 4px;
$padding-md: 8px;
$padding-lg: 16px;
$padding-xl: 32px;
$padding-xxl: 64px;
$padding-xxxl: 128px;

// margins
$margin-xs: 2px;
$margin-sm: 4px;
$margin-md: 8px;
$margin-lg: 16px;
$margin-xl: 32px;
$margin-xxl: 64px;

// font-sizes
$font-size-xs: 10px;
$font-size-sm: 13px;
$font-size-md: 15px;
$font-size-lg: 17px;
$font-size-xl: 21px;
$font-size-xmd: 27px;
$font-size-xxl: 34px;
$font-size-xxxl: 44px;