.email_verification_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #06ceb3;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #06ceb3, #04798f);
  background: linear-gradient(to bottom, #06ceb3, #04798f); 
}

.logo {
  width: auto;
  height: 120px;
  margin-top: -40px;
}

.header_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}

.page_header {
  color: white;
  font-size: 35px;
  margin-top: 35px;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 10px;
}

.input_label {
  color: white;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.error_message {
  color: #762942;
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.simple_text {
  color: lightgray;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 20px;
}