@import 'styles/variables';

.section_about_container {
  background-color: white;
  display: flex;
  flex: 1;
  align-items: center;
  padding: 0 60px;
  padding: $padding-xxl 60px;

  .section_about_content_container {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    flex: 1;

    .about_title {
      user-select: none;
      margin-bottom: 16px;
      color: darkred;
      font-size: 65px;
      line-height: 44px;
      font-weight: 600;
      margin-bottom: 35px;
    }

    .paragraph_container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .paragraph_image {
        width: 360px;
        height: 360px;
      }

      .paragraph_image2 {
        width: 360px;
        height: 360px;
      }
    }

    .about_text {
      color: #666666;
      line-height: 1.8;
      font-size: 17px;
      vertical-align: baseline;
      user-select: none;
      padding-bottom: 10px;
      margin: 2px;
    }
    .about_text_2 {
      color: #555555;
      line-height: 1.8;
      font-size: 21px;
      vertical-align: baseline;
      user-select: none;
      padding-bottom: 10px;
      margin: 2px;
    }

    .about_btn {
      margin-top: 35px;
      align-self: flex-start;
      height: 50px;
      background: -webkit-linear-gradient(to right, #06ceb3, #1890ff);
      background: linear-gradient(to right, #06ceb3, #1890ff);
      border: none;
      border-radius: 4px;
      color: #ffffff;
      cursor: pointer;
      font-weight: 500;
      outline: none;
      font-size: 17px;
      padding: 8px 32px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      user-select: none;
      white-space: nowrap;
    }
  }

  .section_about_image_container {
    display: flex;
    flex: 1;
  }
}

@media (max-width: 768px) {
  .section_about_container {
    flex-direction: column;
    padding: 0 15px;

    .section_about_content_container {
      padding-top: 60px;

      .paragraph_container {
        flex-direction: column;

        .paragraph_image {
          width: 280px;
          height: auto;
        }

        .paragraph_image2 {
          display: none;
        }
      }
    }
  }
}
