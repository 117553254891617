.modal_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
}

.menu_container {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 80px;
  right: 25px;
}

.menu_item_container {
  display: flex;
  margin-top: 30px;
  font-size: 20px;
  cursor: pointer;
}

.menu_item_name {
  color: white;
  background: #00b8ffe0;
  padding: 0px 18px;
  margin-right: 18px;
  border-radius: 9px;
}

.menu_item_icon_container {
  color: white;
  width: 35px;
  height: 35px;
  background: #00b8ffe0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
}

.menu_item_icon_container i {
  margin: 0 !important;
  padding: 0 !important;
}