@import 'styles/variables';

.section_activities_container {
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 64px 60px;
  position: relative;

  .activities_background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .section_activities_title {
    margin-bottom: $margin-lg;
    color: $color-black;
    font-size: $font-size-xxl;
    line-height: 44px;
    font-weight: 600;
    text-align: center;
  }

  .activities_cards_container {
    display: flex;
    flex: 1;
    justify-content: space-between;

    .activity_card {
      display: flex;
      flex: 1;
      margin: 30px 0px;
      box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
      border-radius: 4px;

      &:nth-child(even) {
        margin-left: 60px;
      }

      .activity_card_image {
        width: 100%;
        height: auto;
      }

      .activity_card_content {
        display: flex;
        flex-direction: column;
        padding: 30px;
        justify-content: center;

        .activity_card_content_main_title {
          font-size: 21px;
          line-height: 30px;
          font-weight: 600;
        }

        .activity_card_content_sub_title {
          color: #000;
          line-height: 1.8;
          font-size: 17px;
        }

        .activity_card_content_text {
          color: #000;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .section_activities_container {
    .activities_cards_container {
      flex-direction: column;

      .activity_card {
        &:nth-child(even) {
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section_activities_container {
    padding: 64px 15px;

    .activities_cards_container {
      flex-direction: column;

      .activity_card {
        flex-direction: column;

        &:nth-child(even) {
          margin-left: 0px;
        }

        .activity_card_image {
          width: 280px;
          height: auto;
          padding-left: 10px;
          padding-top: 10px;
        }

        .activity_card_content {
          padding: 10px;
        }
      }
    }
  }
}
