.main_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
  overflow: hidden;
}

.main_content_container {
  display: flex;
  flex: 1;
}

.warning_container {
  text-align: center;
  background-color: yellowgreen;
  color: white;
  font-size: x-large;
}

@media screen and (max-width: 992px) {
  .main_content_container {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
  }
}
