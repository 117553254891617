@import 'styles/variables';

.modal_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team_works_cards {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(248, 249, 250);
  width: 700px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 30px 35px;
  user-select: none;

  .team_works_card_img {
    margin-bottom: $margin-xl;

    img {
      width: 180px;
      height: 180px;
      border-radius: 50%;
    }
  }

  .team_works_card_header {
    h3 {
      font-size: 17px;
      line-height: 30px;
      font-weight: 600;
      text-align: center;
    }
  }

  .team_works_card_container_about {
    p {
      color: gray;
      font-size: 14px;
    }
  }

  .team_works_card_container {
    p {
      color: #000;
      line-height: 1.8;
      font-size: 14px;
      font-weight: 600;
    }
  }
}

@media (max-width: 768px) {
  .team_works_cards {
    width: 300px;
    padding: 5px 5px;

    .team_works_card_img {
      margin-bottom: 0px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
    }

    .team_works_card_header {
      h3 {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
      }
    }

    .team_works_card_container_about {
      p {
        color: gray;
        font-size: 11px;
      }
    }

    .team_works_card_container {
      p {
        color: #000;
        line-height: 1.8;
        font-size: 12px;
      }
    }
  }
}
