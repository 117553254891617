.settings_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background-color: white;
  padding: 20px;
}

.account_settings_container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  overflow: auto;
  height: 80%;
}

.settings_header {
  margin-bottom: 15px;
}

.settings_card_container {
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 249, 250);
  border-radius: 10px;
  box-shadow: 3px 3px 8px #8888887a;
}

.settings_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid darkgray;
  cursor: pointer;
}

.settings_row:last-child {
  border: none;
}

.settings_row_label {
  color: #5f6368;
}

.settings_row_label i {
  margin-right: 15px;
  padding: 5px;
}

.input_container {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-top: 10px;
}

.input_label {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.selectInput {
  min-width: auto !important;
  /* margin-left: 10px;
  margin-right: 10px; */
}
