.topbar_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ff3131;
  padding: 10px 20px;
}

.logo_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app_logo {
  height: 45px;
  width: auto;
}

.hamburger_menu {
  display: none;
  cursor: pointer;
  color: white;
  margin-left: 20px;
}

@media screen and (max-width: 992px) {
  .hamburger_menu {
    display: flex;
  }
}

.icon_container {
  border: 1px solid #ec407a;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ec407a;
  cursor: pointer;
}

.icon_container i {
  margin: 0px !important;
  padding: 0px !important;
}

.icons {
  color: white !important;
}

.profile_photo {
  border-style: none;
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
