.users_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
  padding: 40px 20px;
}

.users_container .ant-table-body {
  max-height: 450px !important;
}
