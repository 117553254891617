@import 'styles/variables';

.section_mentors_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 64px 60px;
  justify-content: center;
  background: rgba(233, 128, 121, 0.85);

  .section_mentors_title {
    margin-bottom: $margin-xxl;
    color: white;
    font-size: $font-size-xxl;
    line-height: 44px;
    font-weight: 600;
    align-self: center;
  }

  .section_mentors_content {
    display: flex;
    justify-content: space-around;

    .section_mentors_part {
      .mentors_part_title {
        font-size: $font-size-xl;
        line-height: 21px;
        font-weight: 600;
        color: #f8c45f;
        margin-bottom: 30px;
        user-select: none;
      }

      .mentors_part_text {
        color: white;
        margin-bottom: 30px;
        font-size: 17px;
        font-weight: 600;
        user-select: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .section_mentors_container {
    padding: 64px 15px;

    .section_mentors_content {
      flex-direction: column;

      .section_mentors_info_container {
        margin-left: 0px !important;
        margin-top: 30px;
      }
    }
  }
}
