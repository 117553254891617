.settings_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background-color: white;
  padding: 20px;
}

.account_settings_container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

.settings_header {
  margin-bottom: 15px;
}

.settings_card_container {
  display: flex;
  flex-direction: column;
  background-color: rgb(248, 249, 250);
  border-radius: 10px;
  box-shadow: 3px 3px 8px #8888887a;
  overflow: auto;
  height: 78%;
  padding: 10px;
  padding-bottom: 80px;
  max-height: 700px;
}

.settings_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid darkgray;
  cursor: pointer;
}

.settings_row:last-child {
  border: none;
}

.settings_row_label {
  color: #5f6368;
}

.settings_row_label i {
  margin-right: 15px;
  padding: 5px;
}
.select {
  width: 200px;
}
