.modal_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.80);
}

.sidebar_container {
  display: flex;
  flex-direction: column;
  background: #04798f;
  width: 200px;
  height: 100vh;
  overflow: auto;
}

.app_logo {
  height: 80px;
  width: auto;
}

.logo_container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_list_container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.menu_list_container a {
  text-decoration: none;
  color: #fff;
  padding: 20px;
}

.menu_list_container a:hover {
  color: salmon;
}

.active_link {
  color: salmon !important;
}

.menu_list_container a:nth-child(odd) {
  background-color: #087084;
}

.menu_icon {
  margin-right: 10px !important;
}
