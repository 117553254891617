.modal_container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
}

.profile_card_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: rgb(248, 249, 250);
  width: 354px;
  height: auto;
  position: absolute;
  top: 70px;
  right: 8px;
  border: 1px solid #ccc;
  border-radius: 8px;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 20px 33px;
}

.icons_container {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.photo_icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-left: -19px;
}

input[type='file'] {
  display: none;
  cursor: pointer;
}

.photo_icon i {
  margin: 0 !important;
  padding: 0 !important;
  color: #5f6368;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px solid #ec407a;
  background-color: #ec407a;
}

.icon_container i {
  margin: 0 !important;
  padding: 0 !important;
  color: white;
  font-size: 34px !important;
  line-height: 34px !important;
}

.name_text {
  margin-top: 10px;
  color: #202124;
  letter-spacing: 0.29px;
  font-weight: 500;
  font-size: 16px;
  margin: 0;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mail_text {
  color: #5f6368;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: normal;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
}

.settings_link_container {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  background-color: rgb(248, 249, 250);
  border: 1px solid #dadce0;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  color: #3c4043;
  display: inline-block;
  letter-spacing: 0.25px;
  margin: 16px 0;
  max-width: 254px;
  outline: 0;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spacer {
  height: 1px;
  width: 100%;
  background-color: #dadce0;
}

.signout_container {
  font-weight: 500;
  font-size: 14px;
  background-color: rgb(248, 249, 250);
  border: 1px solid #dadce0;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  letter-spacing: 0.15px;
  margin-top: 16px;
  outline: 0;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  white-space: normal;
}

.profile_photo {
  border-style: none;
  width: 85px;
  height: 85px;
  object-fit: cover;
  border-radius: 50%;
}

/* .app_logo {
  height: 80px;
  width: auto;
}

.logo_container {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_list_container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.menu_list_container a {
  text-decoration: none;
  color: #fff;
  padding: 20px;
}

.menu_list_container a:hover {
  color: salmon;
}

.active_link {
  color: salmon !important;
}

.menu_list_container a:nth-child(odd) {
  background-color: #087084;
}

.menu_icon {
  margin-right: 10px !important;
} */
