.header_container {
  display: flex;
  padding: 40px 20px;
  background-color: gainsboro;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.content_container {
  display: flex;
  padding: 40px 20px;
  background-color: gainsboro;
  border-radius: 10px;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10px;
  height: 100vh;
}
.chart_title {
  text-align-last: center;
  font-size: x-large;
  font-weight: 700;
}
