.users_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  height: 95vh;
  padding: 40px 20px;
}

.users_container .ant-table-body {
  max-height: 450px !important;
}

.scoreInput {
  width: 100%;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
    }
}
