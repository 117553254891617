@import 'styles/variables';

.section_team_container{
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: $padding-xxl 60px;
    user-select: none;
    align-items: center;
    background-color: #f4f6fd;

    .section_team_details {
        margin-bottom: $margin-lg;
        display: flex;
        flex-direction: column;
        align-items: center;

        .section_team_title {
            margin-bottom: $margin-lg;
            color: $color-black;
            font-size: $font-size-xxl;
            line-height: 44px;
            font-weight: 600;
        }

        .section_team_info {
            color: #6e6e6e;
            line-height: 1.8;
            font-size: $font-size-lg;
            text-align: center;
        }
    }

    .card_container {
        display: flex;
        justify-content: center;
        padding: $padding-lg;
        border: none;

        .team_works_cards {
            transform: translateY(0);
            background: $color-white;
            border-radius: 4px;
            box-shadow: 7px 5px 30px 0 rgba(72,73,121,0.15);
            cursor: pointer;
            width: 330px;
            flex: 1 1 0px;
            padding: 30px 35px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .team_works_card_img {
                margin-bottom: $margin-xl;

                img {
                    width: 180px;
                    height: 180px;
                    border-radius: 50%;
                }
            }

            .team_works_card_header {
                h3 {
                    font-size: 21px;
                    line-height: 30px;
                    font-weight: 600;
                    text-align: center;
                }
            }

            .team_works_card_container {
                p {
                    color: #000;
                    line-height: 1.8;
                    font-size: 17px;
                }
            }

        }

        .team_works_cards_1:hover {
            background: linear-gradient(#ff5819, #9198e5);

            h3 {
                color: #ffff;
            }

            p {
                color: #ffff;
            }
        }

        .team_works_cards_2:hover {
            background: linear-gradient(#8989ec, #dc7409);

            h3 {
                color: #ffff;
            }

            p {
                color: #ffff;
            }
        }

        .team_works_cards_3:hover {
            background: linear-gradient(#fd5dfd, #30e8d7);

            h3 {
                color: #ffff;
            }

            p {
                color: #ffff;
            }
        }
    }

}